import React from "react"
import styled from 'styled-components'
import media from "../../style/media"

import Logo from '../../images/ig.svg'

const HeaderCon = styled.div`
  margin: 0.5em;
  height: 3em;
  img {
    width: 1.5em;
    float: right;
  }
  ${media.tablet`
    margin: 1em;
    height: 5em;
  `}
`

const Header = ({ siteTitle }) => (
  <header>
    <HeaderCon>
      <a href="https://www.instagram.com/_andpartner/" target="_blank" rel="noreferrer">
        <img className="logo" src={Logo} alt="logo" />
      </a>
    </HeaderCon>
  </header>
)

export default Header
