import React from "react"
import styled from 'styled-components'
import media from "../../style/media"

import Halo from "../../images/halo.svg"

const FooterCon = styled.div`
  font-family: sans-serif;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  color: #0a132d;
  .address {
    grid-column: 1;
    display: none;
  }
  .halo {
    grid-column: 1;
    p {
      margin: auto 0;
    }
    display: inline-flex;
    justify-content: flex-start;
    font-size: 0.8em;
    img {
      margin: auto 0;
      height: 1em;
      margin: auto 0.3em;
    }
  }
  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    .address {
      display: block;
      margin: auto 0;
      a {
        color: #0a132d;
        text-decoration: none;
        font-size: 0.8em;
      }
    }
    .halo {
      grid-column: 2;
      p {
        margin: auto 0;
      }
      display: inline-flex;
      justify-content: flex-end;
      font-size: 0.8em;
      img {
        margin: auto 0;
        height: 1em;
        margin: 2.1em 0.3em 0;
      }
    }
  `}
`

const Footer = () => (
  <FooterCon>
    <div className="address">
      <a href="https://goo.gl/maps/TKdzz4AiuFNuUV1q6" target="_blank" rel="noreferrer">
        <p>The Conservatory, 2nd Floor, 13 Baker Street,<br/>
        Rosebank, 2196</p>
      </a>
    </div>
    <div className="halo">
      <p>Part of the</p>
      <a href="https://www.brandhalo.co.za/" target="_blank" rel="noreferrer">
        <img src={Halo} alt="halo" />
      </a>
      <p>group.</p>
    </div>
  </FooterCon>
)

export default Footer
